import React from 'react'
import Header from '../Header'

const CashPortfolio = () => {
    return (
        <>
            <Header />
            <div class="page-content-wrapper">
                <div class="pb-3"></div>
                <div class="container">
                    <p class="text-black mb-0 ms-2" style={{ fontSize: "11px" }}>Min Capital Required 20000</p>
                    <div class="card mb-3" style={{ border: "1px solid #dee2e6 !important" }}>
                        <div class="card-body direction-rtl p-5" style={{ backgroundColor: "aliceblue" }}>
                            <div class="d-flex" style={{ justifyContent: "space-between" }}>
                                <p class="text-primary mb-0" style={{ fontSize: "11px" }}>HDFC Bank Ltd</p>
                                <p class="text-black text-secondary mb-0" style={{ fontSize: "11px" }}><span class="text-success pe-2" style={{ fontWeight: 500 }}>LTP</span><i class="bi-check-circle-fill text-success"></i></p>
                            </div>
                        </div>
                    </div>
                    <p class="text-black mb-0 ms-2" style={{ fontSize: "11px" }}>Min Capital Required 20000</p>
                    <div class="card mb-3" style={{ border: "1px solid #dee2e6 !important" }}>
                        <div class="card-body direction-rtl p-5" style={{ backgroundColor: "aliceblue" }}>
                            <div class="d-flex" style={{ justifyContent: "space-between" }}>
                                <p class="text-primary mb-0" style={{ fontSize: "11px" }}>ICICI Bank Ltd</p>
                                <p class="text-black text-secondary mb-0" style={{ fontSize: "11px" }}><span class="text-success pe-2" style={{ fontWeight: 500 }}>LTP</span><i class="bi-check-circle-fill text-success"></i></p>
                            </div>
                        </div>
                    </div>
                    <p class="text-black mb-0 ms-2" style={{ fontSize: "11px" }}>Min Capital Required 20000</p>
                    <div class="card mb-3" style={{ border: "1px solid #dee2e6 !important" }}>
                        <div class="card-body direction-rtl p-5" style={{ backgroundColor: "aliceblue" }}>
                            <div class="d-flex" style={{ justifyContent: "space-between" }}>
                                <p class="text-primary mb-0" style={{ fontSize: "11px" }}>IDFC Bank Ltd</p>
                                <p class="text-black text-secondary mb-0" style={{ fontSize: "11px" }}><span class="text-success pe-2" style={{ fontWeight: 500 }}>LTP</span><i class="bi-check-circle-fill text-success"></i></p>
                            </div>
                        </div>
                    </div>
                    <p class="text-black mb-0 ms-2" style={{ fontSize: "11px" }}>Min Capital Required 20000</p>
                    <div class="card mb-3" style={{ border: "1px solid #dee2e6 !important" }}>
                        <div class="card-body direction-rtl p-5" style={{ backgroundColor: "aliceblue" }}>
                            <div class="d-flex" style={{ justifyContent: "space-between" }}>
                                <p class="text-primary mb-0" style={{ fontSize: "11px" }}>NESTLE</p>
                                <p class="text-black text-secondary mb-0" style={{ fontSize: "11px" }}><span class="text-success pe-2" style={{ fontWeight: 500 }}>LTP</span><i class="bi-check-circle-fill text-success"></i></p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="pb-3"></div>

            </div>
        </>
    )
}

export default CashPortfolio
