import React from 'react'
import Header from '../Header'

const Positional = () => {
    return (
        <>
            <Header />
            <div class="page-content-wrapper">
                <div class="pb-3"></div>
                <div class="container">
                    <div class="card mb-3 border border-light" style={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px" }}>
                        <div class="card-body direction-rtl p-2">
                            <div class="d-flex" style={{ justifyContent: "space-between" }}>
                                <p class="text-black mb-0" style={{ fontSize: "11px" }}><span class="bg-success rounded text-white pt-1 pb-1 ps-2 pe-2 me-2">B</span> NIFTY 15 Dec 7800 CE</p>
                                <p class="text-black text-secondary mb-0" style={{ fontSize: "11px" }}><span class="text-black" style={{ fontWeight: 500 }}>1Lot</span></p>
                            </div>
                        </div>
                        <div class="card-body direction-rtl p-2">
                            <div class="d-flex" style={{ justifyContent: "space-between" }}>
                                <p class="text-black mb-0" style={{ fontSize: "11px" }}><span class="bg-danger rounded text-white pt-1 pb-1 ps-2 pe-2 me-2">S</span>NIFTY 15 Dec 8600 CE</p>
                                <p class="text-black text-secondary mb-0" style={{ fontSize: "11px" }}><span class="text-black" style={{ fontWeight: 500 }}>1Lot</span></p>
                            </div>
                        </div>
                        <div class="ps-2 pb-1 d-flex border-top" style={{ justifyContent: "space-between" }}>
                            <p class="text-black text-primary mb-0 pe-1 pt-1 m-1" style={{ fontSize: "12px" }}><span class="text-black">P&L for 1Lot = </span>+1000</p>
                            <p class="text-white bg-success mb-0 p-1 pe-1 ps-1 m-1 rounded" style={{ fontSize: "11px" }}>Trade = open</p>
                        </div>
                    </div>

                    <div class="card mb-3 border border-light" style={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px" }}>
                        <div class="card-body direction-rtl p-2" style={{ backgroundColor: "antiquewhite" }}>
                            <div class="d-flex" style={{ justifyContent: "space-between" }}>
                                <p class="text-black mb-0" style={{ fontSize: "11px" }}><i class="bi-circle-fill text-danger pe-2"></i>Close Time - 09:25AM</p>
                            </div>
                        </div>
                        <div class="card-body direction-rtl p-2">
                            <div class="d-flex" style={{ justifyContent: "space-between" }}>
                                <p class="text-black mb-0" style={{ fontSize: "11px" }}><span class="bg-danger rounded text-white pt-1 pb-1 ps-2 pe-2 me-2">S</span>NIFTY 15 Dec 7800 CE</p>
                                <p class="text-black text-secondary mb-0" style={{ fontSize: "11px" }}><span class="text-black" style={{ fontWeight: 500 }}>1Lot</span></p>
                            </div>
                        </div>
                        <div class="card-body direction-rtl p-2">
                            <div class="d-flex" style={{ justifyContent: "space-between" }}>
                                <p class="text-black mb-0" style={{ fontSize: "11px" }}><span class="bg-danger rounded text-white pt-1 pb-1 ps-2 pe-2 me-2">S</span>NIFTY 15 Dec 8600 CE</p>
                                <p class="text-black text-secondary mb-0" style={{ fontSize: "11px" }}><span class="text-black" style={{ fontWeight: 500 }}>1Lot</span></p>
                            </div>
                        </div>
                        <div class="card-body direction-rtl p-2">
                            <div class="d-flex" style={{ justifyContent: "space-between" }}>
                                <p class="text-black mb-0" style={{ fontSize: "11px" }}><span class="bg-success rounded text-white pt-1 pb-1 ps-2 pe-2 me-2">B</span>NIFTY 15 Dec 8600 CE</p>
                                <p class="text-black text-secondary mb-0" style={{ fontSize: "11px" }}><span class="text-black" style={{ fontWeight: 500 }}>1Lot</span></p>
                            </div>
                        </div>
                        <div class="card-body direction-rtl p-2">
                            <div class="d-flex" style={{ justifyContent: "space-between" }}>
                                <p class="text-black mb-0" style={{ fontSize: "11px" }}><span class="bg-success rounded text-white pt-1 pb-1 ps-2 pe-2 me-2">B</span>NIFTY 15 Dec 8600 CE</p>
                                <p class="text-black text-secondary mb-0" style={{ fontSize: "11px" }}><span class="text-black" style={{ fontWeight: 500 }}>1Lot</span></p>
                            </div>
                        </div>
                        <div class="ps-2 pb-1 d-flex border-top" style={{ justifyContent: "space-between" }}>
                            <p class="text-black text-primary mb-0 pe-1 pt-1 m-1" style={{ fontSize: "12px" }}><span class="text-black">P&L for 1Lot = </span>+3000</p>
                            <p class="text-white bg-danger rounded mb-0 p-1 pe-1 ps-1 m-1" style={{ fontSize: "11px" }}>Trade = closed</p>
                        </div>
                    </div>

                    <div class="card mb-3 border border-light" style={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px" }}>
                        <div class="card-body direction-rtl p-2">
                            <div class="d-flex" style={{ justifyContent: "space-between" }}>
                                <p class="text-black mb-0" style={{ fontSize: "11px" }}><span class="bg-success rounded text-white pt-1 pb-1 ps-2 pe-2 me-2">B</span>NIFTY 15 Dec 7800 CE</p>
                                <p class="text-black text-secondary mb-0" style={{ fontSize: "11px" }}><span class="text-black" style={{ fontWeight: 500 }}>1Lot</span></p>
                            </div>
                        </div>
                        <div class="card-body direction-rtl p-2">
                            <div class="d-flex" style={{ justifyContent: "space-between" }}>
                                <p class="text-black mb-0" style={{ fontSize: "11px" }}><span class="bg-danger rounded text-white pt-1 pb-1 ps-2 pe-2 me-2">S</span>NIFTY 15 Dec 8600 CE</p>
                                <p class="text-black text-secondary mb-0" style={{ fontSize: "11px" }}><span class="text-black" style={{ fontWeight: 500 }}>1Lot</span></p>
                            </div>
                        </div>
                        <div class="ps-2 pb-1 d-flex border-top" style={{ justifyContent: "space-between" }}>
                            <p class="text-white bg-success mb-0 p-1 pe-1 ps-1 m-1" style={{ fontSize: "11px" }}>Trade = open</p>
                            <p class="text-black text-primary mb-0 pe-1 pt-1 m-1" style={{ fontSize: "12px" }}><span class="text-black">P&L for 1Lot = </span>1000</p>
                        </div>
                    </div>

                    <div class="card mb-3 border border-light" style={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px" }}>
                        <div class="card-body direction-rtl p-2">
                            <div class="d-flex" style={{ justifyContent: "space-between" }}>
                                <p class="text-black mb-0" style={{ fontSize: "11px" }}><span class="bg-success rounded text-white pt-1 pb-1 ps-2 pe-2 me-2">B</span>NIFTY 15 Dec 7800 CE</p>
                                <p class="text-black text-secondary mb-0" style={{ fontSize: "11px" }}><span class="text-black" style={{ fontWeight: 500 }}>1Lot</span></p>
                            </div>
                        </div>
                        <div class="card-body direction-rtl p-2">
                            <div class="d-flex" style={{ justifyContent: "space-between" }}>
                                <p class="text-black mb-0" style={{ fontSize: "11px" }}><span class="bg-danger rounded text-white pt-1 pb-1 ps-2 pe-2 me-2">S</span>NIFTY 15 Dec 8600 CE</p>
                                <p class="text-black text-secondary mb-0" style={{ fontSize: "11px" }}><span class="text-black" style={{ fontWeight: 500 }}>1Lot</span></p>
                            </div>
                        </div>
                        <div class="ps-2 pb-1 d-flex border-top" style={{ justifyContent: "space-between" }}>
                            <p class="text-white bg-success mb-0 p-1 pe-1 ps-1 m-1" style={{ fontSize: "11px" }}>Trade = open</p>
                            <p class="text-black text-primary mb-0 pe-1 pt-1 m-1" style={{ fontSize: "12px" }}><span class="text-black">P&L for 1Lot = </span>1000</p>
                        </div>
                    </div>

                </div>

                <div class="pb-3"></div>

            </div>
        </>
    )
}

export default Positional
