import React from 'react'

const LiveUpdates = () => {
    return (
        <div>
            {/* <div id="preloader">
                <div class="spinner-grow text-primary" role="status"><span class="visually-hidden">Loading...</span></div>
            </div>
            <div class="internet-connection-status" id="internetStatus"></div> */}
            <div class="header-area" id="headerArea">
                <div class="container">

                    <div class="header-content header-style-five position-relative d-flex align-items-center justify-content-between">

                        <div class="logo-wrapper mt-3"><p class="bg-warning text-black ps-3 pe-3 pt-1 pb-1">Live updates</p></div>
                        <div class="logo-wrapper mt-3"><p class="bg-warning text-black ps-3 pe-3 pt-1 pb-1">Articles</p></div>
                    </div>

                </div>
            </div>
            <div class="page-content-wrapper">
                <div class="pb-3"></div>
                <div class="container">
                    <div class="card mb-3 border border-light" style={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px" }}>
                        <div class="card-body direction-rtl p-2" style={{ backgroundColor: "antiquewhite" }}>
                            <div class="d-flex" style={{ justifyContent: "center" }}>
                                <p class="text-black mb-0 text-center" style={{ fontSize: "11px" }}>Sectors Performance</p>
                            </div>
                        </div>
                        <div class="card-body direction-rtl p-2" style={{ borderBottom: "1px solid lightgray" }}>
                            <div class="d-flex" style={{ justifyContent: "space-between" }}>
                                <p class="text-black mb-0" style={{ fontSize: "12px" }}>Out performers</p>
                                <p class="text-black mb-0" style={{ fontSize: "12px" }}>Under performers</p>
                            </div>
                        </div>
                        <div class="card-body direction-rtl p-2">
                            <div class="d-flex" style={{ justifyContent: "space-between" }}>
                                <p class="text-black mb-0" style={{ fontSize: "12px" }}>Oil & gas 0.8%</p>
                                <p class="text-black text-black mb-0" style={{ fontSize: "12px" }}>technology  -2%</p>
                            </div>
                        </div>
                        <div class="card-body direction-rtl p-2">
                            <div class="d-flex" style={{ justifyContent: "space-between" }}>
                                <p class="text-black mb-0" style={{ fontSize: "12px" }}>FMCG 0.8%</p>
                                <p class="text-black text-black mb-0" style={{ fontSize: "12px" }}>Finance  -0.5%</p>
                            </div>
                        </div>
                    </div>

                    {/* <p class="text-black mb-0 ms-2" style={{ fontSize: "11px" }}>Date : 16/02/2023 10:15AM</p> */}
                    <div class="card mb-3 border border-light" style={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px" }}>
                        <div class="card-body direction-rtl p-2" style={{ backgroundColor: "antiquewhite" }}>
                            <div class="d-flex" style={{ justifyContent: "space-between" }}>
                                <p class="text-black mb-0" style={{ fontSize: "11px" }}><i class="bi-circle-fill text-success pe-2"></i>Date : 09/12/2022 10:15AM</p>
                            </div>
                        </div>
                        <div class="card-body direction-rtl p-2">
                            <div class="d-flex" style={{ justifyContent: "space-between" }}>
                                <p class="text-black mb-0 p-2" style={{ fontSize: "13px" }}>Short covering on the puts seen significantly on IDFC First bank look for</p>
                            </div>
                        </div>
                        <div class="ps-2 pb-1 d-flex border-top" style={{ justifyContent: "end" }}>
                            <p class="text-black text-white mb-0 p-1 ps-2 pe-2 m-1" style={{ fontSize: "12px", borderRadius: "5px", backgroundColor: "#0d6efd !important" }}>Short trades</p>
                        </div>
                    </div>
                    <div class="card mb-3 border border-light" style={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px" }}>
                        <div class="card-body direction-rtl p-2" style={{ backgroundColor: "antiquewhite" }}>
                            <div class="d-flex" style={{ justifyContent: "space-between" }}>
                                <p class="text-black mb-0" style={{ fontSize: "11px" }}><i class="bi-circle-fill text-success pe-2"></i>Date : 09/12/2022 09:25AM</p>
                            </div>
                        </div>
                        <div class="card-body direction-rtl p-2">
                            <div class="d-flex" style={{ justifyContent: "space-between" }}>
                                <p class="text-black mb-0 p-2" style={{ fontSize: "13px" }}>Market opened flat, FMCG on Front runner and technology being dragger</p>
                            </div>
                        </div>
                        <div class="ps-2 pb-1 d-flex border-top" style={{ justifyContent: "end" }}>
                            <p class="text-black text-white mb-0 p-1 ps-2 pe-2 m-1" style={{ fontSize: "12px", borderRadius: "5px", backgroundColor: "#0d6efd !important" }}>No trade misation ideas</p>
                        </div>
                    </div>
                    <div class="card mb-3 border border-light" style={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px" }}>
                        <div class="card-body direction-rtl p-2" style={{ backgroundColor: "antiquewhite" }}>
                            <div class="d-flex" style={{ justifyContent: "space-between" }}>
                                <p class="text-black mb-0" style={{ fontSize: "11px" }}><i class="bi-circle-fill text-success pe-2"></i>Date : 08/12/2022 12:15AM</p>
                            </div>
                        </div>
                        <div class="card-body direction-rtl p-2">
                            <div class="d-flex" style={{ justifyContent: "space-between" }}>
                                <p class="text-black mb-0 p-0" style={{ fontSize: "13px" }}>With increasing longs builter on HDFC Bank since 10days, one can look to trade HDFC Bank on long side.</p>
                            </div>
                        </div>
                    </div>

                    <p class="text-black mb-0 ms-2" style={{ fontSize: "11px" }}>Date : 16/02/2023 10:15AM</p>

                </div>

                <div class="pb-3"></div>

            </div>
        </div>
    )
}

export default LiveUpdates
