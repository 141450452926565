import React from 'react'

const Back = () => {
    return (
        <div>
            <div id="setting-popup-overlay"></div>

            <div class="card setting-popup-card shadow-lg" id="settingCard">
                <div class="card-body">
                    <div class="container">
                        <div class="setting-heading d-flex align-items-center justify-content-between mb-3">
                            <p class="mb-0">Settings</p>
                            <div class="btn-close" id="settingCardClose"></div>
                        </div>
                        <div class="single-setting-panel">
                            <div class="form-check form-switch mb-2">
                                <input class="form-check-input" type="checkbox" id="availabilityStatus" checked />
                                <label class="form-check-label" for="availabilityStatus">Availability status</label>
                            </div>
                        </div>
                        <div class="single-setting-panel">
                            <div class="form-check form-switch mb-2">
                                <input class="form-check-input" type="checkbox" id="sendMeNotifications" checked />
                                <label class="form-check-label" for="sendMeNotifications">Send me notifications</label>
                            </div>
                        </div>
                        <div class="single-setting-panel">
                            <div class="form-check form-switch mb-2">
                                <input class="form-check-input" type="checkbox" id="darkSwitch" />
                                <label class="form-check-label" for="darkSwitch">Dark mode</label>
                            </div>
                        </div>
                        <div class="single-setting-panel">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" id="rtlSwitch" />
                                <label class="form-check-label" for="rtlSwitch">RTL mode</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="header-area" id="headerArea">
                <div class="container">

                    <div class="header-content position-relative d-flex align-items-center justify-content-between">

                        <div class="setting-wrapper">
                            <div class="setting-trigger-btn" id="settingTriggerBtn">
                                <svg class="bi bi-gear" width="18" height="18" viewBox="0 0 16 16" fill="url(#gradientSettings)" xmlns="http://www.w3.org/2000/svg">
                                    <defs>
                                        <linearGradient id="gradientSettings" spreadMethod="pad" x1="0%" y1="0%" x2="100%" y2="100%">
                                            <stop offset="0" style={{ stopColor: "#0134d4", stopOpacity: 1 }}></stop>
                                            <stop offset="100%" style={{ stopColor: "#28a745", stopOpacity: 1 }}></stop>
                                        </linearGradient>
                                    </defs>
                                    <path fill-rule="evenodd" d="M8.837 1.626c-.246-.835-1.428-.835-1.674 0l-.094.319A1.873 1.873 0 0 1 4.377 3.06l-.292-.16c-.764-.415-1.6.42-1.184 1.185l.159.292a1.873 1.873 0 0 1-1.115 2.692l-.319.094c-.835.246-.835 1.428 0 1.674l.319.094a1.873 1.873 0 0 1 1.115 2.693l-.16.291c-.415.764.42 1.6 1.185 1.184l.292-.159a1.873 1.873 0 0 1 2.692 1.116l.094.318c.246.835 1.428.835 1.674 0l.094-.319a1.873 1.873 0 0 1 2.693-1.115l.291.16c.764.415 1.6-.42 1.184-1.185l-.159-.291a1.873 1.873 0 0 1 1.116-2.693l.318-.094c.835-.246.835-1.428 0-1.674l-.319-.094a1.873 1.873 0 0 1-1.115-2.692l.16-.292c.415-.764-.42-1.6-1.185-1.184l-.291.159A1.873 1.873 0 0 1 8.93 1.945l-.094-.319zm-2.633-.283c.527-1.79 3.065-1.79 3.592 0l.094.319a.873.873 0 0 0 1.255.52l.292-.16c1.64-.892 3.434.901 2.54 2.541l-.159.292a.873.873 0 0 0 .52 1.255l.319.094c1.79.527 1.79 3.065 0 3.592l-.319.094a.873.873 0 0 0-.52 1.255l.16.292c.893 1.64-.902 3.434-2.541 2.54l-.292-.159a.873.873 0 0 0-1.255.52l-.094.319c-.527 1.79-3.065 1.79-3.592 0l-.094-.319a.873.873 0 0 0-1.255-.52l-.292.16c-1.64.893-3.433-.902-2.54-2.541l.159-.292a.873.873 0 0 0-.52-1.255l-.319-.094c-1.79-.527-1.79-3.065 0-3.592l.319-.094a.873.873 0 0 0 .52-1.255l-.16-.292c-.892-1.64.902-3.433 2.541-2.54l.292.159a.873.873 0 0 0 1.255-.52l.094-.319z"></path>
                                    <path fill-rule="evenodd" d="M8 5.754a2.246 2.246 0 1 0 0 4.492 2.246 2.246 0 0 0 0-4.492zM4.754 8a3.246 3.246 0 1 1 6.492 0 3.246 3.246 0 0 1-6.492 0z"></path>
                                </svg><span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-content-wrapper py-3">
                <div class="container">

                    <div class="element-heading">

                    </div>
                </div>
                <div class="container">
                    <div class="card">
                        <div class="card-body">
                            <form action="#" method="GET">
                                <div class="form-group">
                                    <label class="form-label" for="defaultSelect">Buy/Sell</label>
                                    <select class="form-select" id="defaultSelect" name="defaultSelect" aria-label="Default select example">
                                        <option value="1" selected="">Choose</option>
                                        <option value="2">Buy</option>
                                        <option value="3">Sell</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label class="form-label" for="defaultSelect">Instrument</label>
                                    <select class="form-select" id="defaultSelect" name="defaultSelect" aria-label="Default select example">
                                        <option value="1" selected="">Choose</option>
                                        <option value="2">Nifty</option>
                                        <option value="3">Reliance</option>
                                        <option value="3">Hdfc</option>
                                        <option value="3">Icici</option>
                                        <option value="3">Sbi</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label class="form-label" for="exampleInputText">Expiry</label>
                                    <input class="form-control" id="exampleInputText" type="date" placeholder="Designing World" />
                                </div>
                                <div class="form-group">
                                    <label class="form-label" for="exampleInputemail">Strike price</label>
                                    <input class="form-control" id="exampleInputemail" type="text" placeholder="enter strike price" />
                                </div>
                                <div class="form-group">
                                    <label class="form-label" for="defaultSelect">C/P</label>
                                    <select class="form-select" id="defaultSelect" name="defaultSelect" aria-label="Default select example">
                                        <option value="1" selected="">Choose</option>
                                        <option value="2">C</option>
                                        <option value="3">P</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label class="form-label" for="exampleInputpassword">Lot</label>
                                    <input class="form-control" id="exampleInputpassword" type="text" placeholder="enter lot" />
                                </div>
                                <div class="form-group">
                                    <label class="form-label" for="exampleInputnumber">Quantity</label>
                                    <input class="form-control" id="exampleInputnumber" type="number" placeholder="12" />
                                </div>
                                <div class="form-group">
                                    <label class="form-label" for="exampleInputtelephone">Price</label>
                                    <input class="form-control" id="exampleInputtelephone" type="tel" placeholder="500" />
                                </div>
                                <button class="btn btn-primary w-100 d-flex align-items-center justify-content-center" type="button">Submit
                                    <svg class="bi bi-arrow-right-short" width="24" height="24" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"></path>
                                    </svg>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Back
