import React from 'react'
import Header from '../Header';

const IntraDay = () => {
    return (
        <>
            <Header />
            <div class="page-content-wrapper">
                <div class="pb-3"></div>
                <div class="container">
                    <p class="text-black mb-0 ms-2" style={{ fontSize: "11px" }}>Date : 16/02/2023 10:15AM</p>
                    <div class="card mb-3 border border-light">
                        <div class="card-body direction-rtl p-2" style={{ backgroundColor: "antiquewhite" }}>
                            <div class="d-flex" style={{ justifyContent: "space-between" }}>
                                <p class="text-black mb-0" style={{ fontSize: "11px" }}>Min Capital Required</p>
                                <p class="text-black text-secondary mb-0" style={{ fontSize: "11px" }}><span class="text-success" style={{ fontWeight: 500 }}>19,475.00</span></p>
                                <p class="text-black text-secondary mb-0" style={{ fontSize: "11px" }}><i class="bi-circle pe-1"></i><i class="bi-circle-fill text-success"></i></p>
                            </div>
                        </div>
                        <div class="card-body direction-rtl p-2">
                            <div class="d-flex" style={{ justifyContent: "space-between" }}>
                                <p class="text-black mb-0" style={{ fontSize: "11px" }}>NIFTY 15 Dec 18,550 CE</p>
                                <p class="text-black text-secondary mb-0" style={{ fontSize: "11px" }}><span class="text-black" style={{ fontWeight: 500 }}>1Lot +500</span></p>
                                <input type="checkbox" class="text-success" />
                            </div>
                        </div>
                        <div class="card-body direction-rtl p-2">
                            <div class="d-flex" style={{ justifyContent: "space-between" }}>
                                <p class="text-black mb-0" style={{ fontSize: "11px" }}>NIFTY 15 Dec 18,600 CE</p>
                                <p class="text-black text-secondary mb-0" style={{ fontSize: "11px" }}><span class="text-black" style={{ fontWeight: 500 }}>1Lot -300</span></p>
                                <p class="text-black text-secondary mb-0" style={{ margin: "26px" }}></p>
                            </div>
                        </div>
                        <div class="ps-2 pb-1 d-flex border-top" style={{ justifyContent: "space-between" }}>
                            <p class="text-black text-danger mb-0 pt-1" style={{ fontSize: "11px" }}>Closed on 16/02/2023</p>
                            <p class="text-black text-primary mb-0 pe-1 pt-1" style={{ fontSize: "11px" }}>P&L 1000</p>
                        </div>
                    </div>

                    <p class="text-black mb-0 ms-2" style={{ fontSize: "11px" }}>Date : 16/02/2023 10:15AM</p>
                    <div class="card mb-3 border border-light">
                        <div class="card-body direction-rtl p-2" style={{ backgroundColor: "antiquewhite" }}>
                            <div class="d-flex" style={{ justifyContent: "space-between" }}>
                                <p class="text-black mb-0" style={{ fontSize: "11px" }}>Min Capital Required</p>
                                <p class="text-black text-secondary mb-0" style={{ fontSize: "11px" }}><span class="text-success" style={{ fontWeight: 500 }}>19,475.00</span></p>
                                <p class="text-black text-secondary mb-0" style={{ fontSize: "11px" }}><i class="bi-circle pe-1"></i><i class="bi-circle-fill text-success"></i></p>
                            </div>
                        </div>
                        <div class="card-body direction-rtl p-2">
                            <div class="d-flex" style={{ justifyContent: "space-between" }}>
                                <p class="text-black mb-0" style={{ fontSize: "11px" }}>NIFTY 15 Dec 18,550 CE</p>
                                <p class="text-black text-secondary mb-0" style={{ fontSize: "11px" }}><span class="text-black" style={{ fontWeight: 500 }}>1Lot +500</span></p>
                                <input type="checkbox" class="text-success" />
                            </div>
                        </div>
                        <div class="card-body direction-rtl p-2">
                            <div class="d-flex" style={{ justifyContent: "space-between" }}>
                                <p class="text-black mb-0" style={{ fontSize: "11px" }}>NIFTY 15 Dec 18,600 CE</p>
                                <p class="text-black text-secondary mb-0" style={{ fontSize: "11px" }}><span class="text-black" style={{ fontWeight: 500 }}>1Lot -300</span></p>
                                <p class="text-black text-secondary mb-0" style={{ margin: "26px" }}></p>
                            </div>
                        </div>
                        <div class="ps-2 pb-1 d-flex border-top" style={{ justifyContent: "space-between" }}>
                            <p class="text-black text-danger mb-0 pt-1" style={{ fontSize: "11px" }}>Closed on 16/02/2023</p>
                        </div>
                    </div>

                    <p class="text-black mb-0 ms-2" style={{ fontSize: "11px" }}>Date : 16/02/2023 10:15AM</p>
                    <div class="card mb-3 border border-light">
                        <div class="card-body direction-rtl p-2" style={{ backgroundColor: "antiquewhite" }}>
                            <div class="d-flex" style={{ justifyContent: "space-between" }}>
                                <p class="text-black mb-0" style={{ fontSize: "11px" }}>Min Capital Required</p>
                                <p class="text-black text-secondary mb-0" style={{ fontSize: "11px" }}><span class="text-success" style={{ fontWeight: 500 }}>19,475.00</span></p>
                                <p class="text-black text-secondary mb-0" style={{ fontSize: "11px" }}><i class="bi-circle pe-1"></i><i class="bi-circle-fill text-success"></i></p>
                            </div>
                        </div>
                        <div class="card-body direction-rtl p-2">
                            <div class="d-flex" style={{ justifyContent: "space-between" }}>
                                <p class="text-black mb-0" style={{ fontSize: "11px" }}>NIFTY 15 Dec 18,550 CE</p>
                                <p class="text-black text-secondary mb-0" style={{ fontSize: "11px" }}><span class="text-black" style={{ fontWeight: 500 }}>1Lot +500</span></p>
                                <input type="checkbox" class="text-success" />
                            </div>
                        </div>
                        <div class="card-body direction-rtl p-2">
                            <div class="d-flex" style={{ justifyContent: "space-between" }}>
                                <p class="text-black mb-0" style={{ fontSize: "11px" }}>NIFTY 15 Dec 18,600 CE</p>
                                <p class="text-black text-secondary mb-0" style={{ fontSize: "11px" }}><span class="text-black" style={{ fontWeight: 500 }}>1Lot -300</span></p>
                                <p class="text-black text-secondary mb-0" style={{ margin: "26px" }}></p>
                            </div>
                        </div>
                        <div class="ps-2 pb-1 d-flex border-top" style={{ justifyContent: "space-between" }}>
                            <p class="text-black text-danger mb-0 pt-1" style={{ fontSize: "11px" }}>Closed on 16/02/2023</p>
                        </div>
                    </div>
                    <p class="text-black mb-0 ms-2" style={{ fontSize: "11px" }}>Date : 16/02/2023 10:15AM</p>
                    <div class="card mb-3 border border-light">
                        <div class="card-body direction-rtl p-2" style={{ backgroundColor: "antiquewhite" }}>
                            <div class="d-flex" style={{ justifyContent: "space-between" }}>
                                <p class="text-black mb-0" style={{ fontSize: "11px" }}>Min Capital Required</p>
                                <p class="text-black text-secondary mb-0" style={{ fontSize: "11px" }}><span class="text-success" style={{ fontWeight: 500 }}>19,475.00</span></p>
                                <p class="text-black text-secondary mb-0" style={{ fontSize: "11px" }}><i class="bi-circle pe-1"></i><i class="bi-circle-fill text-success"></i></p>
                            </div>
                        </div>
                        <div class="card-body direction-rtl p-2">
                            <div class="d-flex" style={{ justifyContent: "space-between" }}>
                                <p class="text-black mb-0" style={{ fontSize: "11px" }}>NIFTY 15 Dec 18,550 CE</p>
                                <p class="text-black text-secondary mb-0" style={{ fontSize: "11px" }}><span class="text-black" style={{ fontWeight: 500 }}>1Lot +500</span></p>
                                <input type="checkbox" class="text-success" />
                            </div>
                        </div>
                        <div class="card-body direction-rtl p-2">
                            <div class="d-flex" style={{ justifyContent: "space-between" }}>
                                <p class="text-black mb-0" style={{ fontSize: "11px" }}>NIFTY 15 Dec 18,600 CE</p>
                                <p class="text-black text-secondary mb-0" style={{ fontSize: "11px" }}><span class="text-black" style={{ fontWeight: 500 }}>1Lot -300</span></p>
                                <p class="text-black text-secondary mb-0" style={{ margin: "26px" }}></p>
                            </div>
                        </div>
                        <div class="ps-2 pb-1 d-flex border-top" style={{ justifyContent: "space-between" }}>
                            <p class="text-black text-danger mb-0 pt-1" style={{ fontSize: "11px" }}>Closed on 16/02/2023</p>
                        </div>
                    </div>
                </div>

                <div class="pb-3"></div>

            </div>
        </>
    )
}

export default IntraDay;
