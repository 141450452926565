import React from 'react'

const AddLiveUpdates = () => {
    return (
        <div>
            <div class="header-area" id="headerArea">
                <div class="container">

                    <div class="header-content header-style-five position-relative d-flex align-items-center justify-content-between">
                        <div class="logo-wrapper mt-3"><p class="bg-warning text-black ps-3 pe-3 pt-1 pb-1"> Add Live updates</p></div>
                    </div>

                </div>
            </div>
            <div class="page-content-wrapper py-3">
                <div class="container">

                    <div class="element-heading">

                    </div>
                </div>
                <div class="container">
                    <div class="card">
                        <div class="card-body">
                            <form action="#" method="GET">


                                <div class="form-group">
                                    <label class="form-label" for="exampleInputDate">Date</label>
                                    <input class="form-control" id="exampleInputDate" type="date" placeholder="Date" />
                                </div>
                                <div class="form-group">
                                    <label class="form-label" for="exampleInputdescription">Description</label>
                                    <input class="form-control" id="exampleInputdescription" type="text" placeholder="enter text here" />
                                </div>


                                <button class="btn btn-primary w-100 d-flex align-items-center justify-content-center" type="button">Submit
                                    <svg class="bi bi-arrow-right-short" width="24" height="24" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"></path>
                                    </svg>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddLiveUpdates
